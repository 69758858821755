<template>
    <ODataLookup :data-object="dsScope_ProcessTreLkp" :whereClause="whereClause" disableRecent ref="lookupRef">
        <ONodeColumn field="Title" width="400" groupBy loadFullStructure boldDisplay />
        <OColumn field="Name" width="400" />
        <template #toolbarActions>
            <div class="d-flex">
                <div class="form-check ms-auto">
                    <input class="form-check-input" id="showAllCheck" type="checkbox"
                        v-model="showAll" @click="showAllProcesses">
                    <label class="form-check-label" for="showAllCheck">{{ $t("Show All") }}</label>
                </div>
            </div>
        </template>
    </ODataLookup>
</template>

<script setup>
import { ref, defineProps, onMounted } from "vue";
import { getDataObjectById } from 'o365-dataobject';
import { ONodeColumn } from 'o365-nodedata';
import 'o365-nodedata';

const lookupRef = ref();
const showAll = ref(false);

const props = defineProps({
    detailApp: {
        type: String,
        default: 'scope-workflow'
    },
    whereClause: {
        type: String,
        default: ''
    }
})

const dsScope_ProcessTreLkp = getDataObjectById("dsScope_ProcessTreLkp");
dsScope_ProcessTreLkp.nodeData.addConfiguration({
    type: 'groupBy',
    fieldName: 'Title'
});
dsScope_ProcessTreLkp.nodeData.enable();

function showAllProcesses(){
    if(!showAll.value){
        dsScope_ProcessTreLkp.recordSource.whereClause = props.whereClause;
        dsScope_ProcessTreLkp.load();
    } else {
        dsScope_ProcessTreLkp.recordSource.whereClause = resolveWhereClause();
        dsScope_ProcessTreLkp.load();
    }
    showAll.value = !showAll.value;
}

function resolveWhereClause(){
    return `${(props.whereClause.length > 0 ? props.whereClause + " AND" : '')} DetailApp_ID = '${props.detailApp}'`;
}

onMounted(() => {
    dsScope_ProcessTreLkp.recordSource.whereClause = resolveWhereClause();    
})

</script>